.navButton{
    border-radius: 25px;
    background-color: #007bff;
    color: white;
    border-color: #007bff;
    margin-right: 2px;
}

.navButton:active{
    color: black;
}

.navButton:focus{
    color: black;
}

.navButton:hover{
    background-color: #035772 !important;
    color: white;
}