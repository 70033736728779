.coursesContainer {
  margin: 0 !important;
  padding: 0 !important;
}

.courseDivStyle {
  width: 100%;
  background-color: #e8e8e8;
  margin: 5px !important;
  padding: 2px 5px 2px 5px;
  border-radius: 10px;
  display: flex;
  justify-content: space-between;
}

.UsrSlctCourseInfoDiv {
  width: 50%;
  float: left;
  padding-left: 10px;
  word-wrap: break-word;
}

.courseMenageDiv {
  float: right;
  display: flex;
  align-items: center;
  justify-content: center;
  padding-right: 10px;
}

.marginRight {
  margin-right: 5px;
}

.roundEdge {
  border-radius: 5px !important;
  padding: 1px;
  height: 28px;
  white-space: nowrap;
}

.couseTitle {
  font-size: 25px;
  color: darkred;
}

.couseTitle:hover {
  font-size: 25px;
  color: #6e0000;
  font-style: normal;
}

.descriptionstyle {
  color: #6c757d;
}

input:focus {
  outline: 0;
}

.otvoriObuka {
  background-color: #007bff !important;
  color: white !important;
  width: 100%;
  margin-top: 5px;
}
.otvoriObukaN {
  background-color: #007bff !important;
  color: white !important;
  width: 100%;
  margin-bottom: 5px;
}
.otvoriObukaP {
  background-color: #007bff !important;
  color: white !important;
  width: 100%;
  margin-bottom: 5px;
}
#upatstvo {
  color: white;
  width: 100%;
}

#prikaciSertifikat {
  color: white;
  width: 100%;
  margin-top: 5px;
}

.prevzemiSertifikat {
  background: #dc3545 !important;
  color: white !important;
  width: 100%;
  margin-top: 5px;
}

.prevzemiSertifikat:hover {
  background: #ff8080 !important;
  color: white;
  width: 100%;
  margin-top: 5px;
}

.postevaluacija {
  background-color: #28a745 !important;
  color: white !important;
  width: 100%;
  white-space: nowrap;
}

.preevaluacija {
  background-color: #28a745 !important;
  color: white !important;
  width: 100%;
  white-space: nowrap;
}

.preevaluacija:hover {
  background-color: #85e085 !important;
}

.postevaluacija:hover {
  background-color: #85e085 !important;
}

#prikaciSertifikat:hover {
  background: #ffcc80 !important;
  color: white;
  width: 100%;
  margin-top: 5px;
}

.otvoriObuka:hover {
  background-color: #4daff6 !important;
  color: white;
}

#upatstvo:hover {
  background-color: #4daff6 !important;
  color: white;
}
.card {
  background-color: #f7f7f7 !important;
}
input[type="checkbox"] {
  display: block;
  background-color: white;
  border: 1px solid #0061af;
  border-radius: 2px;
}

#generateCertificate{
  border-radius: 20px !important;
  font-family: 'Montserrat' !important;
  font-weight: bold;
}
#searchBar{
  font-family: 'Montserrat' !important;
}
@media only screen and (max-width: 600px) {
 .filterCourses{
  flex-direction: column;
 }
#generateCertificate{
  margin-left: 0px !important;
}
}