@media (max-width: 991.98px){

    .kt-login__wrapper{
        width: 100vw !important;
    }
    .kt-login__container{
        width: 100vw !important;
        padding: 10px !important;
    }

    .kt-grid__item.kt-grid__item--fluid.kt-grid__item--center.kt-grid.kt-grid--ver.kt-login__content{
        width: 100vw !important;
    }
    .kt-grid.kt-grid--ver.kt-grid--root.kt-page.kt-grid-page{
        max-width: 100vw !important;
        overflow-x: hidden;
        min-width: 0 !important;

    }
    .registrationTextDiv{
        width: 80% !important;;
        margin: 0 !important;
        vertical-align: baseline !important;
    }
}


/*Desktop*/
#kt_login{
  /*overflow-y: scroll !important;*/

}

.kt-login__wrapper{
    padding: 3px !important;
    margin: 0 !important;
}

.kt-login__logo{
    margin: 0 !important;
}

.kt-login__logo_mz{
    position: relative;
    padding-bottom: 115px;
}


.registrationTextDiv{
    /*width: 95%;*/
    background-color: rgba(255, 255, 255, 0.15);
    backdrop-filter: blur(1px);
    padding: 35px;
    margin: 78px;
    margin-right: 0px;
    border-radius: 13px !important;
    border-top-right-radius: 0px !important;
    border-bottom-right-radius: 0px !important;
    /*margin-top: 190px;*/
}

.registrationText{
    /*margin-top: 50px;*/
    font-size: 14px;
    font-family: Poppins, Helvetica, sans-serif;
    text-align: justify;
}
@media (max-width: 991.98px){

    .kt-login__wrapper{
        width: 100vw !important;
    }
    .kt-login__container{
        width: 100vw !important;
        padding: 10px !important;
    }

    .kt-grid__item.kt-grid__item--fluid.kt-grid__item--center.kt-grid.kt-grid--ver.kt-login__content{
        width: 100vw !important;
    }
    .kt-grid.kt-grid--ver.kt-grid--root.kt-page.kt-grid-page{
        max-width: 100vw !important;
        overflow-x: hidden;
        min-width: 0 !important;

    }
    .registrationTextDiv{
        width: 100% !important;
        margin: 0 !important;
    }
}


/*Desktop*/
#kt_login{
    /*overflow-y: scroll !important;*/

}

#languageButton {
    text-align: center;
    width: 60px;
    height: 35px;
}

.kt-login__wrapper{
    padding: 0 !important;
    margin: 0 !important;
}

.kt-login__logo{
    margin: 0 !important;
}

.registrationTextDiv{
    /*width: 100%;*/
    padding-right: 50px!important;
    background-color: rgba(245, 245, 245, 0.18);
    padding: 20px!important;
}

.registrationText{
    font-size: 14px;
    font-family: Poppins, Helvetica, sans-serif;
    text-align: justify;
}
#kt_login_signin_submit{
    color: white;
    background-color:#034377ff;
    border-radius: 25px !important;
    width: 21rem;
}
#email,#pass{
    color: black;
    font-size: 12px;
    font-family: Poppins, Helvetica, sans-serif;
    font-weight: bold;
}
#login,#signin{
    color: black;
    font-size: 15px;
    font-family: Poppins, Helvetica, sans-serif;
    text-decoration: none;
    font-weight: bold;
}
/*#login:link,#signin:link,#signin:visited{*/
/*    color: lightgrey;*/
/*    border-bottom: solid lightgrey 2px;*/
/*}*/
/*#login:visited{*/
/*    color:black;*/
/*    border-bottom: solid 2px #0061AFff;*/
/*}*/
/*#login:active,#signin:active{*/
/*    color:black;*/
/*    border-bottom: solid 2px #0061AFff;*/
/*}*/
.form-control{
    border-radius: 25px !important;
}

#img1{
    position: relative;
    padding-bottom: 180px;

}
.box-shadow {
    -webkit-box-shadow: 0px 10px 20px 0px rgba(50, 50, 50, 0.52);
    -moz-box-shadow:    0px 10px 20px 0px rgba(50, 50, 50, 0.52);
    box-shadow:         0px 5px 10px 0px rgba(70, 70, 70, 0.12)
}
#form2,#kt_login_forgot{
    color: black;
}
.kt-checkbox>input:checked ~ span{
    background-color: #0061AFff;
}
#reg{
    color: #0061AFff;
    font-weight: bold;
    text-decoration: none;
}
#kt_login_signin_submit
{
    color: white;
    background-color:#034377ff;
    text-transform: uppercase;
    border-radius: 25px !important;
    width: 100%;
    font-family:"Tahoma",sans-serif!important;
    letter-spacing: 2px;
    font-weight: bolder;

}
.footer{
    display:flex!important;
    justify-content:center!important;
    font-size:12px!important;
    position:absolute!important;
    top:935px!important;
    bottom:0!important;
    color:white!important;

}
.registrationTextDiv{
    /*width: 95%;*/
    background-color: rgba(245, 245, 245, 0.15);
    padding: 20px;
    border-radius: 10px;
}

#login,#signup{
    color: lightgrey;
    border-bottom: solid lightgrey 2px;
    font-size: 15px;
    font-family: Poppins, Helvetica, sans-serif;
    text-decoration: none;
    font-weight: bold;
    padding: 10px;
}
a#signup.active, a#login.active{
    color:black;
    border-bottom: solid 2px #0061AFff;

}
@media only screen and (max-width: 1000px) {
    .footer{
        display: none;
    }

}
