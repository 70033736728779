.form-control{
    padding-left: 15px !important;
    border-radius: 20px !important;
    box-shadow: rgba(0, 0, 0, 0.15) 0px 5px 15px;
}
/*This is about the input field for email, gives interesting round border (aesthetic)*/

.existing_account{
    /*margin-bottom: -35px;*/
    padding-left: 90px;
    margin-top: 16px;
    margin-inside: 32px;
}
/*This css class is made about the already existing account
 (Веке имам налог? Најави се)*/

[id=font]{
    font-weight: bold;
    /*face="Verdana";*/
    color: #0061AF;
}
/*changing font to bold on the existing_account*/

[id=reset_pw]{
    border-bottom: 2.5px solid !important;
    border-bottom-color: #0061AF !important;
    padding-bottom: 8px !important;
    position: absolute !important;
    margin-left: 80px !important;
    color: black;
}
/*This is about the bottom border at Ресетирај лозинка? */

/*.copyright_footer{*/
/*    color: white;*/
/*    margin-top: 200px;*/
/*    margin-left: 250px;*/
/*}*/



.box-shadow{
    -webkit-box-shadow: 0px 10px 20px 0px rgba(50, 50, 50, 0.52);
    -moz-box-shadow:    0px 10px 20px 0px rgba(50, 50, 50, 0.52);
    /*box-shadow:         0px 5px 10px 0px rgba(70, 70, 70, 0.12);*/
    box-shadow: rgba(50, 50, 93, 0.25) 0px 13px 27px -5px, rgba(0, 0, 0, 0.3) 0px 8px 16px -8px;
}
/*for the reset_password form*/

.footer_reset{
    display: flex   !important;
    justify-content:center  !important;
    font-size:12px  !important;
    /*top:900px !important;*/
    bottom: 0px  !important;
    position: absolute  !important;
    color:white !important;
}


@media only screen and (min-width: 768px) {
    .registrationContainer{
        padding-left: 0px;
    }
    .footer_reset{
        display: none;
    }

}

/*for the footer at the bottom of resetpassword*/
