@import url('https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,600;1,700;1,800;1,900&display=swap');
body{
    font-family: 'Montserrat';
    overflow-x: hidden;
    background-color: white !important;
}
.navbar.navbar-expand-lg.navbar.navbar-dark
{
background-repeat: no-repeat;
background-size: cover;
background-position: center;


}
a.item{
    color:#034377 !important;
    font-weight: bold;
}

@media only screen and (max-width: 1600px) {
    #emptyDiv{
        display: none;
    }
  
}