.modal-header{
    background: #0085EF;
    border: #0085EF;
    font-family: Montserrat;
    color: white;
    border-top-right-radius: 20px;
    border-top-left-radius: 20px;
    line-height: 150%;
    width: 100%;
    padding: 5px;
}

.modal-body{
    background: #0085EF;
    border: #0085EF;
    width: 100% !important;
    font-family: Montserrat;
    color: white;
    line-height: normal;
    padding: 10px;
}

.modal-footer{
    background: #0085EF;
    border: #0085EF;
    font-family: Montserrat;
    color: white;
    border-bottom-left-radius: 20px;
    border-bottom-right-radius: 20px;
    padding: 5px;
    justify-content: space-between;
}

#edit_modal_close_button{
    background-color: #f64343;
    border-radius: 30px!important;
    color: white!important;
}

#edit_modal_close_button:hover {
    background-color: #fc8484 !important;
    /*box-shadow: #ffffff 0 0 0 3px, transparent 0 0 0 0;*/
    color: white !important;
}

#edit_modal_save_button{
    background-color: #015caf !important;
    border-radius: 30px!important;
    color: white!important;
}

#edit_modal_save_button:hover {
    background-color: #4ea9fa !important;
    /*box-shadow: #ffffff 0 0 0 3px, transparent 0 0 0 0;*/
    color: white !important;
}

.modal-content{
    background: transparent;
    border: 3px solid #0061AF;
    border-radius: 24px;
}


                                                       /* CSS */
 .button-35 {
     align-items: center;
     background-color: white !important;
     border-radius: 10px !important;
     box-shadow: transparent 0 0 0 3px,rgba(18, 18, 18, .1) 0 6px 20px;
     box-sizing: border-box;
     color: #000000 !important;
     cursor: pointer;
     display: inline-flex;
     flex: 1 1 auto;
     font-family: Montserrat;
     font-size: 1.2rem;
     font-weight: 700;
     justify-content: center;
     line-height: 1;
     margin: 5px;
     outline: none;
     padding: 1rem 1.2rem;
     text-align: center;
     text-decoration: none;
     transition: box-shadow .2s,-webkit-box-shadow .2s;
     white-space: nowrap;
     border: 0;
     user-select: none;
     -webkit-user-select: none;
     touch-action: manipulation;
 }

.button-35:hover {
    background-color: #0061AF !important;
    box-shadow: #ffffff 0 0 0 3px, transparent 0 0 0 0;
    color: white !important;
}

#cardButton{
    background-color: #0061AF !important;
    color:  white !important;
    /* background-color: #F7F7F7!important; */
    /* color: #0085EF!important; */
    box-shadow: none;
    /*margin-left: 25px !important;*/
    width: 171px !important;
    height: 33px !important;
    /*padding-left: 5px!important;*/
    /*padding-right: 5px!important;*/
    /*padding-bottom: 25px!important;*/
    /*padding-top: 2px!important;*/
    /*margin-bottom: 6px;*/
    /*border-radius: 8px;*/
    font-family: Montserrat;
    /*margin-top: 100px;*/
}

#cardButton:hover{
    background-color: #62C6FF !important;
    box-shadow:  #F7F7F7 0 0 0 3px, transparent 0 0 0 0 !important;
   
}