.kt-login.kt-login--v6 {
  background: #fff; }
.kt-login.kt-login--v6 .kt-login__aside {
    padding: 0rem;
    background: #fff;
    width: 600px; }
.kt-login.kt-login--v6 .kt-login__aside .kt-login__wrapper {
      -webkit-box-flex: 1;
      -ms-flex: 1;
      flex: 1;
      display: -webkit-box;
      display: -ms-flexbox;
      display: flex;
      -webkit-box-orient: vertical;
      -webkit-box-direction: normal;
      -ms-flex-direction: column;
      flex-direction: column;
      padding: 2rem;
      }
.kt-login.kt-login--v6 .kt-login__aside .kt-login__wrapper .kt-login__container {
        -webkit-box-flex: 1;
        -ms-flex: 1;
        flex: 1;
        width: 400px;
        margin: 0 auto;
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        -webkit-box-align: center;
        -ms-flex-align: center;
        align-items: center;
        padding-bottom: 5rem; }
.kt-login.kt-login--v6 .kt-login__aside .kt-login__wrapper .kt-login__container .kt-login__body {
          width: 100%;
          -webkit-box-flex: 1;
          -ms-flex: 1;
          flex: 1; }
.kt-login.kt-login--v6 .kt-login__aside .kt-login__wrapper .kt-login__container .kt-login__logo {
          text-align: center;
          margin: 1rem auto 4rem auto; }
.kt-login.kt-login--v6 .kt-login__aside .kt-login__wrapper .kt-login__container .kt-login__head {
          margin-top: 1rem;
          text-align: center; }
.kt-login.kt-login--v6 .kt-login__aside .kt-login__wrapper .kt-login__container .kt-login__head .kt-login__title {
            text-align: center;
            font-size: 1rem;
            font-weight: bold;
            color: #595d6e;
            padding:20px;
            /*border-bottom: solid 2px #0061AFff;*/
            }
.kt-login.kt-login--v6 .kt-login__aside .kt-login__wrapper .kt-login__container .kt-login__head .kt-login__desc {
            text-align: left;
            font-size: 0.8rem;
            color: #000000;
            font-weight: 400;
            margin-top: 1.2rem; }
.kt-login.kt-login--v6 .kt-login__aside .kt-login__wrapper .kt-login__container .kt-login__form {
          margin-top: 4rem; }
.kt-login.kt-login--v6 .kt-login__aside .kt-login__wrapper .kt-login__container .kt-login__form .form-group {
            margin: 0;
            padding: 0;
            position: relative; }
.kt-login.kt-login--v6 .kt-login__aside .kt-login__wrapper .kt-login__container .kt-login__form .form-control {
            height: 46px;
            border-radius:10px;
            border:1px solid #E7E7E7;
            padding: 1rem 0;
            margin-top: 0.1rem;
            color: #595d6e;
          }
.kt-login.kt-login--v6 .kt-login__aside .kt-login__wrapper .kt-login__container .kt-login__form .form-control::-moz-placeholder {
              color: #74788d;
              opacity: 1; }
.kt-login.kt-login--v6 .kt-login__aside .kt-login__wrapper .kt-login__container .kt-login__form .form-control:-ms-input-placeholder {
              color: #74788d; }
.kt-login.kt-login--v6 .kt-login__aside .kt-login__wrapper .kt-login__container .kt-login__form .form-control::-webkit-input-placeholder {
              color: #74788d; }
.kt-login.kt-login--v6 .kt-login__aside .kt-login__wrapper .kt-login__container .kt-login__form .form-control.form-control-last {
  height: 46px;
  border-radius:10px;
  border:1px solid #E7E7E7;
  padding: 1rem 0;
  margin-top: 0.1rem;
  color: #595d6e;
            }
.kt-login.kt-login--v6 .kt-login__aside .kt-login__wrapper .kt-login__container .kt-login__actions {
          margin: 3rem 0;
          text-align: center; }
.kt-login.kt-login--v6 .kt-login__aside .kt-login__wrapper .kt-login__container .kt-login__extra {
          margin-top: 2rem;
          display: -webkit-box;
          display: -ms-flexbox;
          display: flex;
          -webkit-box-pack: justify;
          -ms-flex-pack: justify;
          justify-content: space-between; }
.kt-login.kt-login--v6 .kt-login__aside .kt-login__wrapper .kt-login__container .kt-login__extra label {
            margin: 0; }
.kt-login.kt-login--v6 .kt-login__aside .kt-login__wrapper .kt-login__container .kt-login__extra a {
            font-weight: 500;
            color: #595d6e;
            -webkit-transition: color 0.3s ease;
            transition: color 0.3s ease;
            display: inline-block; }
.kt-login.kt-login--v6 .kt-login__aside .kt-login__wrapper .kt-login__container .kt-login__extra a:hover {
              -webkit-transition: color 0.3s ease;
              transition: color 0.3s ease;
              color: #22b9ff; }
.kt-login.kt-login--v6 .kt-login__aside .kt-login__wrapper .kt-login__account {
        text-align: center; }
.kt-login.kt-login--v6 .kt-login__aside .kt-login__wrapper .kt-login__account .kt-login__account-msg {
          font-size: 1rem;
          font-weight: 500;
          color: #74788d; }
.kt-login.kt-login--v6 .kt-login__aside .kt-login__wrapper .kt-login__account .kt-login__account-link {
          font-size: 1rem;
          font-weight: 500;
          color: #595d6e;
          -webkit-transition: color 0.3s ease;
          transition: color 0.3s ease; }
.kt-login.kt-login--v6 .kt-login__aside .kt-login__wrapper .kt-login__account .kt-login__account-link:hover {
            -webkit-transition: color 0.3s ease;
            transition: color 0.3s ease;
            color: #22b9ff; }
.kt-login.kt-login--v6 .btn {
    height: 46px;
    padding-left: 3rem;
    padding-right: 3rem;
  }
.kt-login.kt-login--v6 .btn ~ .btn {
      margin-left: 0.5rem; }
.kt-login.kt-login--v6 .kt-login__content {
    background-size: cover;
    background-repeat: no-repeat; }
.kt-login.kt-login--v6 .kt-login__content .kt-login__section {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-flex: 1;
  -ms-flex: 1;
  flex: 1;
  position: relative;
}
.kt-login.kt-login--v6 .kt-login__content .kt-login__section .kt-login__title {
  color: #ffffff;
  font-size: 3.5rem;
  font-weight: 500;
  margin-bottom: 2rem; }
.kt-login.kt-login--v6 .kt-login__content .kt-login__section .kt-login__desc {
  color: rgba(255, 255, 255, 0.8);
  font-size: 1.1rem;
  font-weight: 400;
}

.kt-login.kt-login--v6.kt-login--signin .kt-login__signup {
  display: none; }

.kt-login.kt-login--v6.kt-login--signin .kt-login__signin {
    margin-inside: 25px;
    display: block; }

.kt-login.kt-login--v6.kt-login--signin .kt-login__forgot {
  display: none; }

.kt-login.kt-login--v6.kt-login--signup .kt-login__signup {
  display: block; }
