input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
}

input[type=number] {
    -moz-appearance:textfield; /* Firefox */
}

.kt-login__form{
    margin-top: 1rem !important;
}

.kt-login__actions{
    margin: 1rem 0 !important;
}

.kt-login.kt-login--v6 .kt-login__aside{
    padding: 0 !important;
   
}
.labelRegister{
    font-size: 15px;
    font-weight: bold;
    color: black;
}
#login,#signup{
    color: lightgrey;
    border-bottom: solid lightgrey 2px;
    font-size: 15px;
    font-family: Poppins, Helvetica, sans-serif;
    text-decoration: none;
    font-weight: bold;
    padding: 10px;
    text-align: center;
    color: #595d6e;
    
}

a#signup.active, a#login.active{
    color:black;
    border-bottom: solid 2px #0061AFff; 

} 
.form-control{
    border-radius: 25px !important;
    
}
#kt_login_signin_submit
{
    color: white;
    background-color:#034377ff;
    text-transform: uppercase;
    border-radius: 25px !important;
    width: 100%;

}
#backToLogin {
    color: #0061AFff;
    text-decoration: none;
    font-weight: bold;
}
.swal2-container.swal2-center>.swal2-popup
{
    border: 3px solid #0061AFff !important;
    border-radius: 10px !important;
}
.swal2-confirm.swal2-styled
{
    background-color: #0061AFff !important;
    width: 100px;
    border-radius: 10px !important;
}

.registrationContainer{
    padding-left: 100px;
}

.footerRegister{
   display:flex;
   justify-content:center!important;
    font-size:12px;
     position:absolute!important;
     top:1400px!important;
     bottom:0;
     color:white;
}
.registerBox{
    margin-top: -430px;
}
@media only screen and (max-width: 1100px) {
   .registrationContainer{
       padding-left: 0px;
   }
   .footerRegister{
  display: none;
   }
   .registerBox{
       margin-top: 0px;
   }

  }