.coursesContainer{
    margin: 0 !important;
    padding: 0 !important;
}

.courseDivStyle{
    width: 100%;
    background-color: #e8e8e8;
    margin: 5px !important;
    padding: 2px 5px 2px 5px;
    border-radius: 10px;
    display: flex;
    justify-content: space-between;
}

.courseInfoDiv{
    float: left;
    padding-left: 10px;
    max-width: 88% !important;
    word-wrap: break-word
}
.courseMenageDiv{
    float: right;
    display: flex;
    align-items: center;
    justify-content: center;
    padding-right: 10px;
}

.roundEdge{
    border-radius: 10px !important;
}

.couseTitle{
    font-size: 25px;
    color: darkred;
}

.couseTitle:hover{
    font-size: 25px;
    color: #6e0000;
    font-style: normal ;
}

.descriptionstyle{
    color: #6c757d;
}
.searchDiv{
    display: flex;
    justify-content: flex-end;
}
input.form-control.date-picker{
    background-color: #0061AF;
    color: white !important;
    border-radius: 10px !important;
   
}
input.form-control.date-picker::placeholder{
    color: white !important;
}
input.form-control.date-picker:placeholder-shown{
border: none;

}
.menuItemDate:hover{
    background-color: #F7F7F7;
}
#dateFromToBtn
{
    font-family: 'Montserrat' !important;
    font-weight: 400;
   
    
}
.MuiButton-root:focus, .MuiButton-root:active{
  
    outline-width: 0;
}
#categorySelect{
margin-left: -5px;
}
@media screen and (max-width:920px) {
    .filterDiv{
        flex-direction: column;
    }
    .searchDiv{
        display: flex;
        justify-content: flex-start !important;
        align-self: start;
       
    }
    #categorySelect{
        margin-left: 0px !important;
        }
    #dateFromToBtn
{
    position: relative;
    left: 0px !important;
    min-width: 100% !important;
}
.MuiPaper-root{
    min-width: 100% !important;
}

}