/*.invalid_input{*/
/*    border-color: #007bff;*/
/*    background: #ffd7d7;*/
/*}*/

/*.invalid_label{*/
/*    color: red;*/
/*}*/

/*.input_required{*/
/*    color: red;*/
/*    border-color: #007bff;*/
/*}*/

.button_category{
    border-radius: 10px!important;
}
.btn{
    border-radius: 10px !important;
    white-space: nowrap;
}

#nameMkInput:focus{
    border: none;
}
/* If input is empty */
#nameMkInput {
    border-color: red;
}
