.files input {
    outline: 2px dashed #92b0b3;
    outline-offset: -10px;
    -webkit-transition: outline-offset .15s ease-in-out, background-color .15s linear;
    transition: outline-offset .15s ease-in-out, background-color .15s linear;
    padding: 120px 0px 85px 35%;
    text-align: center !important;
    margin: 0;
    width: 100% !important;
}
.files input:focus{     outline: 2px dashed #92b0b3;  outline-offset: -10px;
    -webkit-transition: outline-offset .15s ease-in-out, background-color .15s linear;
    transition: outline-offset .15s ease-in-out, background-color .15s linear; border:1px solid #92b0b3;
}
.files{ position:relative}
.files:after {  pointer-events: none;
    position: absolute;
    top: 60px;
    left: 0;
    width: 50px;
    right: 0;
    height: 56px;
    content: "";
    background-image: url(https://image.flaticon.com/icons/png/128/109/109612.png);
    display: block;
    margin: 0 auto;
    background-size: 100%;
    background-repeat: no-repeat;
}
.color input{ background-color:#f1f1f1;}
.files:before {
    position: absolute;
    bottom: -10px;
    left: 0;  pointer-events: none;
    width: 100%;
    right: 0;
    height: 57px;
    content: " or drag it here. ";
    display: block;
    margin: 0 auto;
    color: #2ea591;
    font-weight: 600;
    text-transform: capitalize;
    text-align: center;
}


.uploadSignedFileBtn {
    background-color: transparent !important;
    border-top: none !important;
    border-left: none !important;
    border-right: none !important;
    border-radius: 12px !important;
    color: green !important;
    font-size: 14px !important;
}

.uploadSignedFileBtn:hover {
    border-top: none !important;
    border-radius: 12px !important;
    text-decoration: none !important;
}

.uploadSignedFileBtnRed {
    background-color: transparent !important;
    border-top: none !important;
    border-left: none !important;
    border-right: none !important;
    border-radius: 12px !important;
    color: #dc3545 !important;
    font-size: 14px !important;
}

.uploadSignedFileBtnRed:hover {
    border-top: none !important;
    border-radius: 12px !important;
    text-decoration: none !important;
}

#margBottom {
    margin-top: 2em;
    background-color: red;
    color: white;
    width: 140px; 
    border-radius: 5px !important;
}
.rowCertificate{
    background-color: #E4E4E4;
    text-align: center;
  border: 0;
  border: hidden;
   
}
.downloadCertificate{
    color: gray;
    text-align: center;
    border-radius: 10px;
    border: 8px solid white; 
}
.colCertificate{
border: 8px solid white;    
color: gray;
text-align: center;
border-radius: 10px;


}
.table td, .table th {
    padding: 0.50rem !important;
    text-align: center;
}
#downloadC:focus{
outline: none;
}