.courseCard{
    /*padding-right: 0px !important;*/
    /*padding-left: 0px !important;*/
    background-color: #F7F7F7;
    height: 390px;
    /*width: 300px!important;*/
    /*width: -webkit-fill-available;*/
}

.cardButton{
    background-color: #F7F7F7;
}

.cardBody{
    /*word-break: break-word;*/
    /*background-color: #F7F7F7;*/
    height: 90px !important;
    line-height: 8px;
    padding-bottom: 0;
    padding-right: 0;
    /*padding-left: 5px;*/
    /*display: flex;*/
    /*line-height: 24px;*/
    /*overflow: hidden!important;*/
    /*!*margin-right: 20px;*!*/
    overflow: hidden;
    /*text-overflow: ellipsis;*/
    /*display: -webkit-box;*/
    /*line-height: 48px;*/
    /*max-height: 90px;*/
    /*-webkit-line-clamp: 5; !* Write the number of*/
    /*                          lines you want to be*/
    /*                          displayed *!*/
    /*-webkit-box-orient: vertical;*/
    padding-left: 5px;
}

.courseDateLangDiv{
    bottom: 50px  !important;
    position: absolute  !important;
    /*padding-right: 20px;*/
    padding-left: 0;
}

#courseTitle{
    margin-top: -15px;
    font-family: Montserrat;
    font-weight: Bold;
    font-size: 16px;
    color: black;
    line-height: 16px;
    /*overflow: hidden!important;*/
}

#courseDescription{
    font-family: Montserrat;
    color: #000000 !important;
    alignment: left !important;
    padding-bottom: 30px;
    font-size: 13px!important;
    line-height: 16px;
    overflow: hidden!important;
    max-width: available;
    max-height: 90px;
    margin-top: 0;
}

#courseCategory{
    font-family: Montserrat;
    font-weight: normal;
    color: #000000;
    alignment: left!important;
    font-size: 13px!important;
    /*position: absolute;*/
    /*bottom: 25px;*/
    overflow: hidden!important;
    margin-bottom: 13px;
}

#courseDate{
    font-family: Montserrat;
    font-weight: normal;
    color: #000000;
    alignment: left!important;
    font-size: 10px!important;
    position: absolute;
}

#courseLanguage{
    font-family: Montserrat;
    font-weight: normal;
    color: #000000;
    alignment: left!important;
    font-size: 10px!important;
    padding-left: 130px;
}
