#changeBorder{
    background-color: white;
    border-radius: 25px;
    width: 780px;

}

#changePass{

    border-bottom: solid !important;
    border-bottom-color: #0061AF !important;

}
#promeniLozinka{
    background-color: #0061AF !important;
    color:white !important;
}
#promeniLozinka:hover{
    background-color: #62C6FF !important;
}